/* 모바일 좌우 스크롤 금지하는 overflow가 있어서 sticky가 동작하지않습니다
그래서 useEffect 함수를 사용하여 해결합니다. 2023.07.04 윤지석 */

/* 모바일 좌우(수평) 스크롤 금지 */
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

p {
  margin: 0;
  padding: 0;
}

header {
  width: 100vw;
  background: #4d6ded;
  box-sizing: border-box;
  position: relative;
}
header main img {
  max-width: 100%;
  height: auto;
}
main {
  padding-bottom: 50px;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.side_menu {
  z-index: 999;
  background: #fff;
}

ul.menu_wrap {
  list-style: none;
  font-family: NotoSansKR-Bold;
  font-size: 20px;
  color: #293f52;
  padding-left: 24px;
}
ul.menu_wrap li {
  margin-bottom: 30px;
}

.navigation {
  display: flex;
  width: 100vw;
  height: 64px;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.15));
  background: #fff;
  font-family: NotoSansKR-Bold;
  padding-left: 16px;
  box-sizing: border-box;
  text-align: center;
  z-index: 990;
}

.navigation.opacity {
  opacity: 0;
}
.navigation .top_menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navigation .top_menu span.Active_menu {
  color: #293f52 !important;
  font-family: NotoSansKR-Black !important;
}

.navigation .top_menu span {
  color: #dbdbdb;
  text-decoration: none;
}

#sticker {
  position: fixed;
  top: 0px;
  left: 0px;
}
.sticky {
  position: fixed;
  top: 0px;
  left: 0px;
}

/* 플로팅 버튼 CSS ------------------------------------- */
.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 22px;
  width: 46px;
  height: 46px;
  background-color: #4d6ded;
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 4px 4px 16px rgb(0, 0, 0, 0.45);
  z-index: 9999999;
}

.scroll-to-top-button .arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #fff;
  margin-top: -2px;
}

/* 전화하기 링크 스타일 */
.call-button {
  text-decoration: none;
  color: #fff;
}

/* 서비스 1 wellpps 솔루션 ------------------------------------- */

.serviceBox {
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 16px;
  position: relative;
}

.serviceBox_1 .service_subtext {
  font-family: NotoSansKR-Light;
}
.serviceBox_1 .sub_style {
  margin-bottom: 4px;
}
.serviceBox_1 .service_maintext {
  font-family: NotoSansKR-Bold;
}

.serviceBox_1 img {
  width: 100%;
  height: auto;
  position: relative;
  top: 4px;
}

/* 이미지 슬라이더 */
.slider_box {
  box-sizing: border-box;
  padding: 0 16px 0 16px;
}
.purple {
  background: #eff0ff;
}
.deep_purple {
  background: #dbdefc;
}
.green {
  background: #e8f8e9;
}
.deep_green {
  background: #d2ebd3;
}

.yellow {
  background: #fdf8e5;
}
.deep_yellow {
  background: #f7ecc4;
}

.slider_info .main_text {
  font-family: NotoSansKR-Bold;
}
.slider_info .sub_text {
  font-family: NotoSansKR-Light;
}

/* 주요서비스 1 배경색 */
.wellpps_background {
  background-color: #e3f0f9;
}
.phoneissue_background {
  background-color: #ffeddd;
}
.ppspay_background {
  background-color: #ffd6d6;
}
.wellnanum_background {
  background-color: #e0fbff;
}
.lab_background {
  background-color: #c9def4;
}

/* 주요서비스 1 폰트컬러 */
.wellpps_fontColor {
  color: #1d425c !important;
}
.phoneissue_fontColor {
  color: #ff7600;
}
.ppspay_fontColor {
  color: #e61515;
}
.wellnanum_fontColor {
  color: #0f5591;
}
.lab_fontColor {
  color: #0c182f;
}

.img_url {
  display: flex;
  justify-content: center;
}
.img_url_child {
  font-size: 16px;
  font-family: NotoSansKR-Medium;
  color:rgba(217,108,61, 1.0);
  text-align: center;
  margin: 40px auto 0 auto;
  padding: 8px 36px;
  border-radius: 24px;
  border: 1px solid rgba(217,108,61, 1.0);
  background-color: #fff;
  box-shadow: 4px 6px 12px rgba(217,108,61, 0.08);
  cursor: pointer;
  text-decoration: none;
}
.img_url_child:active {
  position: relative;
  top: 1px;
  right: 1px;
  box-shadow: none;
}
.img_url_colorRed {
  border: 1px solid #ff5050;
  color: #ff5050;
}
.img_url_colorskyblue {
  border: 1px solid #1788cd;
  color: #1788cd;
}

/* 업무방식 >> 우리가 일하는 방식 -------------------------------------*/

.slider_box2 {
  box-sizing: border-box;
  padding: 24px;
  font-family: GothamBlack;
  font-size: 40px;
}

.slider_box2 .slider_child {
  height: 328px;
  border-radius: 16px;
  box-shadow: 6px 3px 32px #00000024;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}
.slider_child .workflow_img {
  width: 220px;
  margin: 0 auto;
}

.slider_blueBack {
  background-color: #0382fa !important;
}
.slider_purpleBack {
  background: #441949;
}

.workflow_para {
  font-family: NotoSansKR-Light;
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
}

/* 조직구성 >> 우리의 구성원들 -------------------------------------*/
.team_card_bundle {
  margin-top: 28px;
}
.team_card {
  box-sizing: border-box;
  padding: 0 16px;
  margin-bottom: 8px;
}
.team_wrap {
  width: 100%;
  height: 150px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 6px 3px 32px #00000024;
}

.team_wrap .emoji {
  width: 88px;
  height: 88px;
  position: relative;
}
.team_wrap .emoji img {
  position: absolute;
  width: 60%;
  height: 60%;
  left: 25%;
  top: 15%;
  object-fit: cover;
}
.team_info .team_main {
  font-family: NotoSansKR-Bold !important;
  font-size: 20px;
  margin-bottom: 4px!important;
}
.team_info .team_sub {
  font-family: NotoSansKR-Light !important;
  font-size: 16px;
}

/* 걸어온길 >> 연혁 -------------------------------------*/

#history_wrap {
  width: 100%;
  margin-top: 28px;
}
#history_wrap .year {
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.year_text {
  border-bottom: 1px solid #0077ed;
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  color: #0077ed;
  line-height: 50px;
}
.month_wrap {
  margin-top: 16px;
  font-size: 14px;
  font-family: NotoSansKR-Medium;
  display: flex;
  color: #656565;
}

.month_wrap .month {
  font-family: NotoSansKR-Bold;
  font-size: 18px;
  color: #0077ed;
  margin-right: 14px;
  flex-basis: 20%;
}
.month_wrap .sub_text {
  flex-basis: 55%;
  position: relative;
  top: 4px;
}
.month_wrap .month_img {
  padding: 8px;
  flex-basis: 25%;
  position: relative;
}
.month_wrap .month_img img {
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  right: 0;
}

/* CONTACT - 오시는길 -------------------------------------*/

.tab_wrap {
  margin-top: 28px;
}
.tab_wrap .tab_bar {
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  color: #d6d6d6;
  font-family: NotoSansKR-Bold;
  margin-bottom: 30px;
}

.tab_wrap .tab_bar .active_tab {
  border-bottom: 2px solid #1a1a1a;
  color: #1a1a1a;
  padding-bottom: 4px;
}
.active_margin {
  margin-right: 36px;
}
.address_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 32px;
}


.address_list {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.address_list .title {
  font-size: 18px;
  color: #0077ed;
  font-family: NotoSansKR-Bold;
  flex-basis: 40%;
}

.address_list .info {
  font-size: 14px;
  color: #656565;
  font-family: NotoSansKR-Medium;
  flex-basis: 60%;
}
.address_wrap .map {
  height: 350px;
}


/* CONTACT- 업무제휴 -------------------------------------------*/
.input_box {
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
}
.input_box .input_wrap {
  font-size: 14px;
  color: #1a1a1a;
  font-family: NotoSansKR-Medium;
}
.input_list {
  margin-bottom: 30px;
}
.input_list input {
  margin-top: 10px;
  width: 100%;
  height: 42px;
  border: 1px solid #0077ed;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 16px;
  color: #0077ed;
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
}
.input_list input::placeholder {
  color: #d6d6d6;
}

.radio-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.radio-container input[type="radio"] {
  display: none;
}

.radio-container label {
  display: inline-block;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #d6d6d6;
  box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
  color: #d6d6d6;
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  flex-basis: 50%;
  text-align: center;
}

.radio-container input[type="radio"]:checked + label {
  border: 1px solid #0077ed;
  color: #0077ed;
}

button.request_btn {
  height: 54px;
  box-shadow: 6px 3px 32px #00000024;
  border-radius: 8px;
  background-color: #0077ed;
  font-family: NotoSansKR-Black;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.1);
  margin-top: 40px;
  cursor:pointer;
  width: 100%;
  border: none;
}
button.disabled_btn {
  background-color: rgba(0,119,237, 0.65)!important;
}

.request_btn:active {
  position: relative;
  top: 1px;
  right: 1px;
  box-shadow: none;
}
.off {
  display: none;
}

.title_wrap{
  display: flex;
  justify-content: space-between;
}
.input_title {
  font-family: NotoSansKR-Medium !important;
  font-size: 14px;
}
.detail_info {
  color: #0077ed;
  padding-bottom: 2px;
  border-bottom: 2px solid #0077ed;
  font-family: NotoSansKR-bold !important;
  box-sizing: border-box;
  cursor:pointer;
}  

.require {
  color: red;
}

/* footer --------------------------------------------------- */
#footer {
  box-sizing: border-box;
}
.ask_us {
  display: flex;
  justify-content: space-between;
}
.ask_us .ask_child {
  flex-basis: 50%;
  text-align: center;
  font-family: NotoSansKR-Black;
  font-size: 14px;
  color: #fff;
  padding: 24px 0;
  background-color: #202020;
}

.family_wrap {
  box-sizing: border-box;
  padding: 0 8%;
  background-color: #141414;
}
.site_list {
  display: flex;
  justify-content: space-between;
  color: #808080;
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #555555;
}

.infomation_footer {
  padding: 16px 8% 40px 8%;
  box-sizing: border-box;
  background-color: #202020;
}
.infomation_footer img {
  width: 7rem;
  margin: 20px 0;
}

.footer_info {
  font-family: NotoSansKR-Regular;
  color: #808080;
  font-size: 14px;
  line-height: 22px;
}


/* Mobile1 --------------------------------------------------*/
@media (min-width: 360px) {
  body {
    overflow-x: hidden;
  }
  nav {
    padding: 16px 24px;
  }
  main {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  main p {
    padding-left: 16px;
    margin: 0;
  }
  main .sub_text {
    font-family: NotoSansKR-Medium;
    font-size: 16px;
  }
  main .main_text {
    font-family: GothamBlack;
    margin-top: 8px;
    font-size: 44px;
    line-height: 54px;
    filter: drop-shadow(4px 3px 5px rgba(0, 0, 0, 0.25));
  }
  .navigation {
    box-sizing: border-box;
    overflow-x: scroll;
  }

  .navigation .top_menu span {
    width: 100px;
    font-size: 16px;
  }

  #service {
    margin-top: 80px;
  }
  #service p {
    font-family: NotoSansKR-Bold;
    margin: 0 16px;
  }
  #service .service_subText {
    font-size: 20px;
    margin-bottom: 8px;
    color: #0077ed;
  }

  #service .service_slogan {
    font-size: 26px;
    color: #1a1a1a;
  }

  /* wellpps 서비스소개 - 360px */
  .wellpps_img img {
    border-radius: 20px 20px 0 0;
  }
  .serviceBox {
    padding: 0 16px;
  }
  .serviceBox_1 {
    box-sizing: border-box;
    padding: 40px 30px 0 30px;
    margin-top: 28px;
    border-radius: 16px;
    position: relative;
    transition: 0.5s;
  }

  .serviceBox_1 span.pop_text {
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 0 30px;
    color: #fff;
    font-family: NotoSansKR-Light;
    line-height: 28px;
  }
  .serviceBox_1 .service_subtext {
    font-size: 20px;
  }
  .serviceBox_1 .service_maintext {
    font-size: 20px;
    margin-bottom: 16px;
  }
  .address_wrap .map {
    width: 100%;
    height: 300px;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.085);
    border-radius: 16px;
    margin-top: 50px;
  }  
  .address_box {
    padding: 0 16px;
  }  
  
  /* 커스텀 체크박스 ---=------=-------=-------=-------=-------=  */
  .checkbox-container {
    display: flex;
    align-items: center;
    margin: 10px 0 30px 0;
    flex-wrap: wrap;
  }
  
  .checkbox-container input[type="checkbox"] {
    display: none;
  }
  
  .checkbox-container label {
    display: inline-block;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 10px 10px 0;
    border: 1px solid #d6d6d6;
    box-shadow: 6px 4px 12px rgba(0, 0, 0, 0.1);
    color: #d6d6d6;
    font-family: NotoSansKR-Bold;
    font-size: 16px;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label {
    border: 1px solid #0077ed;
    color: #0077ed;
  }  

  /* 업무 제휴 비고 ---=------=-------=-------=-------=-------= */
  .textarea {
    box-sizing: border-box;
    padding: 12px;
    font-family: NotoSansKR-bold !important;
    font-size: 14px;
    border-radius: 8px;
    height: 104px;
    border: 1px solid #0077ed;
    box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
    margin-top: 10px;
    color: #0077ed;
    resize: none;
    width: 100%;
  }
  .textarea::placeholder {
    color: #d6d6d6;
  }   

}
/* wellpps 이미지 슬라이드 */
.slider_box {
  height: 328px;
  margin-top: 16px;
}
.slider_child {
  height: 100%;
  border-radius: 16px;
}

.slider_info {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.icon_box {
  width: 88px;
  height: 88px;
  margin: 0 auto;
  position: relative;
  border-radius: 16px;
  margin-top: 40px;
}
.icon_box img.purple_1 {
  position: absolute;
  top: 18%;
  left: 12%;
  width: 64%;
  height: 64%;
  object-fit: cover;
}
.icon_box img.purple_1_1 {
  position: absolute;
  top: 18%;
  left: 18%;
  width: 64%;
  height: 64%;
  object-fit: cover;
}

.icon_box img.green_1 {
  position: absolute;
  top: 18%;
  left: 18%;
  width: 64%;
  height: 64%;
  object-fit: cover;
}

.icon_box img.yellow_1 {
  position: absolute;
  top: 12%;
  left: 12%;
  width: 72%;
  height: 72%;
  object-fit: cover;
}

.slider_info .main_text {
  margin-top: 20px;
  font-size: 24px;
  box-sizing: border-box;
  padding: 0 16px;
}
.slider_info .sub_text {
  font-size: 16px;
  box-sizing: border-box;
  padding: 0 24px;
}

.textPop_close {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #1a1a1a;
  position: absolute;
  z-index: 20;
  right: 8px;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textPop_close img {
  width: 14px;
  height: 14px;
}

.site_list span a {
  text-decoration: none;
  color: #808080;
}

/* Mobile2 --------------------------------------------------*/
@media (min-width: 452px) {
  nav#moblie_global {
    padding: 20px 26px;
  }
  main p {
    padding-left: 24px;
    margin: 0;
  }
  nav#moblie_global .logo {
    width: 100px;
  }
  nav#moblie_global .ham {
    width: 24px;
  }
  main .sub_text {
    font-size: 18px;
  }
  main .main_text {
    font-size: 52px;
    line-height: 62px;
  }
  .navigation {
    box-sizing: border-box;
    overflow-x: scroll;
    height: 72px;
  }
  .navigation .top_menu span {
    width: 100px;
    font-size: 16px;
  }

  #service p {
    padding: 0 24px;
  }
  #service .service_subText {
    font-size: 22px;
  }

  #service .service_slogan {
    font-size: 30px;
  }

  /* wellpps 서비스소개 - 452px */

  .wellpps_img img {
    border-radius: 24px 24px 0 0;
  }  
  .serviceBox {
    padding: 0 24px;
  }
  .serviceBox_1 .service_subtext {
    font-size: 26px;
  }
  .serviceBox_1 .service_maintext {
    font-size: 26px;
    margin-bottom: 16px;
  }
  .slider_box {
    box-sizing: border-box;
    padding: 0 24px;
  }
  #history_wrap .year {
    padding: 0 40px;
  }

  /* CONTACT - 우리와 함께 하는 방법 */
  .team_card {
    padding: 0 40px;
  }

  /* CONTACT - 우리와 함께 하는 방법 */
  .input_box {
    padding: 0 40px;
  }
  .tab_wrap .tab_bar {
    padding: 0 40px;
  }
  .address_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
  }

}

/* Mobile3 --------------------------------------------------*/
@media (min-width: 641px) {
  body {
    overflow-x: hidden;
  }

  nav#moblie_global .logo {
    width: 120px;
  }
  nav#moblie_global .ham {
    width: 28px;
  }
  main .sub_text {
    font-size: 24px;
  }
  main .main_text {
    font-size: 72px;
    line-height: 86px;
  }

  #service .service_subText {
    font-size: 26px;
    margin-bottom: 8px;
  }

  #service .service_slogan {
    font-size: 38px;
  }
  /* wellpps 서비스소개 - 641px */
  .wellpps_img img {
    border-radius: 30px 30px 0 0;
  }  
  .serviceBox {
    padding: 0 40px;
  }
  .serviceBox_1 {
    padding: 40px 50px 0 50px;
    margin-top: 28px;
  }
  .serviceBox_1 .service_subtext {
    font-size: 38px;
  }
  .serviceBox_1 .service_maintext {
    font-size: 38px;
    margin-bottom: 16px;
  }
  .slider_box {
    box-sizing: border-box;
    padding: 0 40px;
  }

  .serviceBox_1 span.pop_text {
    font-size: 24px;
    line-height: 40px;
  }
  .textPop_close {
    width: 50px;
    height: 50px;
    border: 2px solid #1a1a1a;
    z-index: 20;
    right: 24px;
    top: -20px;
  }
  .textPop_close img {
    width: 28px;
    height: 28px;
  }

  #history_wrap .year {
    padding: 0 40px;
  }
}

/* Tablet --------------------------------------------------*/
@media (min-width: 768px) {
  nav#moblie_global .logo {
    width: 150px;
  }
  nav#moblie_global .ham {
    width: 36px;
  }
  main .sub_text {
    font-size: 30px;
  }
  main .main_text {
    font-size: 90px;
    line-height: 110px;
  }
  .navigation .top_menu span {
    width: 110px;
    font-size: 18px;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    height: 64px;
    box-sizing: border-box;
  }

  #service .service_subText {
    font-size: 32px;
    margin-bottom: 8px;
  }

  #service .service_slogan {
    font-size: 46px;
  }
  /* wellpps 서비스소개 - 768px */
  .wellpps_img img {
    border-radius: 40px 40px 0 0;
  }
  .serviceBox {
    padding: 0 40px;
  }
  .serviceBox_1 {
    padding: 50px 60px 0 60px;
    border-radius: 26px;
  }
  .serviceBox_1 .service_subtext {
    font-size: 48px;
  }
  .serviceBox_1 .service_maintext {
    font-size: 48px;
    margin-bottom: 16px;
  }
  .slider_child {
    border-radius: 26px;
  }

  .serviceBox_1 span.pop_text {
    font-size: 32px;
    line-height: 56px;
  }
  .textPop_close {
    width: 60px;
    height: 60px;
    border: 2px solid #1a1a1a;
    z-index: 20;
    right: 24px;
    top: -20px;
  }
  .textPop_close img {
    width: 34px;
    height: 34px;
  }

  /* 업무방식 - 우리가 일하는 방식 ------------------------------------*/
  .slider_box2 {
    padding: 36px;
  }
  .workflow_para {
    padding: 0 36px;
    font-size: 20px;
    line-height: 30px;
  }

  /* 조직구성 - 우리의 구성원들 ------------------------------------*/
  .team_card {
    padding: 0 36px;
  }
  .team_wrap {
    height: 160px;
  }

  /* 걸어온길 >> 연혁 -------------------------------------*/

  .year_text {
    font-size: 34px;
  }
  .month_wrap {
    font-size: 18px;
  }

  .month_wrap .month {
    font-size: 26px;
  }

  /* CONTACT - 오시는길 -------------------------------------*/

  .tab_wrap .tab_bar {
    padding: 0 40px;
    font-size: 22px;
  }
  .address_box {
    padding: 0 56px;
  }
  .address_list .title {
    font-size: 22px;
  }

  .address_list .info {
    font-size: 18px;
  }

  /* CONTACT- 업무제휴 ------------------------------------------- */
  .input_box {
    padding: 0 40px;
  }
  .input_box .input_wrap {
    font-size: 18px;
  }
  .input_list {
    margin-bottom: 40px;
  }
  .input_list input {
    margin-top: 14px;
    height: 54px;
    font-size: 20px;
  }

  .radio-container {
    margin-top: 14px;
  }
  .radio-container label {
    padding: 16px 12px;
    font-size: 20px;
  }
  .request_btn {
    height: 64px;
    margin-top: 50px;
    font-size: 24px;
  }
}

/* Laptop --------------------------------------------------*/
@media (min-width: 1024px) {
  header main img {
    width: 800px;
    height: 605;
    margin: 0 auto;
  }

  nav#moblie_global {
    padding: 28px 34px;
  }
  main p {
    padding-left: 34px;
    margin: 0;
  }
  main .sub_text {
    font-size: 40px;
  }
  main .main_text {
    font-size: 114px;
    line-height: 134px;
  }
  .navigation .top_menu span {
    width: 110px;
    font-size: 18px;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    height: 72px;
  }
  #service {
    margin-top: 120px;
  }
  #service p {
    padding: 0;
    margin: 0;
  }
  #service .service_subText {
    font-size: 30px;
    text-align: center;
  }

  #service .service_slogan {
    font-size: 40px;
    text-align: center;
  }

  /* wellpps 서비스소개 - 1024px */
  #service_laptop {
    width: 900px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 80px auto 0 auto;
  }
  #service_laptop .left_explainBox {
    width: 500px;
  }
  .left_explainBox p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 28px;
    color: #1a1a1a;
  }
  .left_explainBox p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 16px;
    color: #a0a0a0;
  }
  .left_explainBox p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 100px 0 !important;
    color: #1a1a1a;
  }
  p.paragraph .blue {
    color: #0077ed;
    font-family: NotoSansKR-Medium;
    font-size: 16px;
  }
  #service_laptop .right_explainBox {
    width: 380px;
  }
  .service_card {
    width: 100%;
    box-sizing: border-box;
    height: 200px;
    border-radius: 30px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
  }
  .wellpps_img img.box_shadow {
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 30px;
  }

  .icon_box {
    width: 88px;
    height: 88px;
    border-radius: 16px;
    margin: 0 20px 0 40px;
  }
  .icon_box img.purple_1 {
    position: absolute;
    top: 18%;
    left: 12%;
    width: 64%;
    height: 64%;
    object-fit: cover;
  }

  .icon_box img.green_1 {
    position: absolute;
    top: 18%;
    left: 18%;
    width: 64%;
    height: 64%;
    object-fit: cover;
  }

  .icon_box img.yellow_1 {
    position: absolute;
    top: 12%;
    left: 12%;
    width: 72%;
    height: 72%;
    object-fit: cover;
  }

  .service_card .main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 22px;
    color: #1a1a1a;
  }
  .service_card .sub_text {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    color: #1a1a1a;
  }
  .service_card .sub_text_1 {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    color: #1a1a1a;
  }
  /* phoneissue 서비스소개 - 1024px */
  #service_laptop .right_explainBox2 {
    width: 460px;
  }
  #service_laptop .left_explainBox2 {
    width: 400px;
    position: relative;
    top: 100px;
  }
  .left_explainBox2 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 28px;
    color: #1a1a1a;
  }
  .left_explainBox2 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 16px;
    color: #a0a0a0;
  }
  .left_explainBox2 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 60px 0 !important;
    color: #1a1a1a;
  }

  /* ppspay 서비스소개 - 1024px */
  #service_laptop .right_explainBox3 {
    width: 460px;
  }
  #service_laptop .left_explainBox3 {
    width: 350px;
    position: relative;
    top: 30px;
  }
  .left_explainBox3 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 28px;
    color: #1a1a1a;
  }
  .left_explainBox3 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 16px;
    color: #a0a0a0;
  }
  .left_explainBox3 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 60px 0 !important;
    color: #1a1a1a;
  }
  /* 통신나눔 서비스소개 - 1024px */
  #service_laptop .right_explainBox4 {
    width: 460px;
  }
  #service_laptop .left_explainBox4 {
    width: 410px;
    position: relative;
    top: 10px;
  }
  .left_explainBox4 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 28px;
    color: #1a1a1a;
  }
  .left_explainBox4 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 16px;
    color: #a0a0a0;
  }
  .left_explainBox4 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 60px 0 !important;
    color: #1a1a1a;
  }

  .img_url_child {
    font-size: 14px;
    font-family: NotoSansKR-Medium;
    margin: 30px auto 0 auto;
    padding: 8px 36px;
    border-radius: 24px;
  }

  /* 업무방식 - 우리가 일하는 방식 */
  .workflow_text {
    width: 900px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
  }
  .workflow_text p {
    font-size: 18px;
    font-family: NotoSansKR-Light !important;
    text-align: center;
  }
  .workflow_box {
    width: 900px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px auto 0 auto;
  }
  .workflow_box_child {
    width: 210px;
    height: 210px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .workflow_box_child .main_text {
    font-family: GothamBlack;
    text-align: center;
    font-size: 30px;
    color: #20385b;
  }
  .workflow_box_child img {
    width: 140px;
    margin: 0 auto;
  }

  /* 조직구성 - 우리의 구성원들 -------------------------------*/

  .team_card_bundle {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 900px;
    margin: 28px auto 0 auto;
  }
  .team_card {
    width: 430px;
    padding: 10px;
    margin: 0;
  }
  .team_wrap .emoji {
    margin-right: 30px;
  }
  /* 조직구성 - 우리의 구성원들 -------------------------------*/
  #history_wrap .year {
    padding: 0;
    width: 900px;
    margin: 0 auto 40px auto;
  }

  /* CONTACT 우리와 함께 하는 방법 -------------------------------*/
  .laptop_contact {
    width: 900px;
    margin: 50px auto 0 auto;
    display: flex;
  }
  .address_wrap {
    flex-basis: 60%;
  }
  .address_margin {
    margin: 0 30px 0 0;
  }
  .address_child {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .address_child .sub_text {
    font-family: NotoSansKR-Light !important;
  }
  .address_child .title {
    font-size: 18px;
    font-family: NotoSansKR-Bold !important;
    color: #0077ed;
  }
  .request_wrap {
    flex-basis: 40%;
    margin-left: 50px;
  }
  .map {
    width: 100%;
    height: 200px;
    background: #fff;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  .request_wrap {
    box-sizing: border-box;
    padding: 34px;
    width: 100%;
    height: 890px;
    background: #fff;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  .request_title {
    font-family: NotoSansKR-Bold !important;
    font-size: 24px;
    display: flex;
    align-items: center;
  }
  .request_title img {
    width: 36px;
    position: relative;
    top: 3px;
  }
  .input_box_labtop {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .input_title {
    font-family: NotoSansKR-Medium !important;
    font-size: 14px;
  }
  input.input_text {
    box-sizing: border-box;
    padding-left: 12px;
    font-family: NotoSansKR-Bold !important;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    border: 1px solid #0077ed;
    box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
    margin-top: 10px;
    color: #0077ed;
  }
  input.input_text::placeholder {
    color: #d6d6d6;
  }

  .radio-container label {
    padding: 8px 2px;
    margin: 0;
    font-size: 16px;
  }
  .request_btn {
    height: 50px;
    font-size: 18px;
    margin-top: 30px;
  }
  
  .checkbox-container input[type="checkbox"]:checked + label {
    border: 1px solid #0077ed;
    color: #0077ed;
  }
  .address_wrap .map {
    height: 704px;
  }

  .open_writing {
    width:100%;
    height:1px;
    background-color: #0077ed;
    border: 1px solid #0077ed;
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }
  .opening_btn{
    position:relative;
    bottom: 12px;
    width: 30px;
    height: 30px;
    background-color: #0077ed;
    border: none;
    border-radius: 50%;
    color: #fff;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 4px 4px 16px rgb(0, 0, 0, 0.35);
  }
  .textarea {
    box-sizing: border-box;
    padding: 12px;
    font-family: NotoSansKR-bold !important;
    font-size: 14px;
    border-radius: 8px;
    height: 104px;
    border: 1px solid #0077ed;
    box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
    margin-top: 10px;
    color: #0077ed;
    resize: none;
  }
  .textarea::placeholder {
    color: #d6d6d6;
  }    

}

/* Laptop --------------------------------------------------*/
@media (min-width: 1024px) and (max-width: 1399px) {
  .service_card .main_text {  
    line-height: 26px;
    margin-bottom: 6px!important; 
  }
  .phoneissue_img {
    display: flex;
    justify-content: space-between;
  }
  .phoneissue_img a img{
    box-shadow: 6px 3px 8px rgba(0,0,0, 0.08);
    border-radius: 20px;
  }
  .phoneissue_img a.pi_2 {
    margin-top: 70px;
  }  
  .ppspay_img {
    display: flex;
    justify-content: space-between;
  }
  .ppspay_img a img{
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 30px;
  }
  .ppspay_img a.py_2 {
    position:relative;
    top: 80px;
    right: 100px;
  }    
  .wellnanum_img img {
    box-shadow: rgba(0, 0, 0, 0.14) 6px 3px 16px;
    border-radius: 30px;
  }   
  .lab_img img {
    box-shadow: rgba(0, 0, 0, 0.14) 6px 3px 16px;
    border-radius: 30px;
  }    

  /* 커스텀 체크박스 =-------=-------=-------=-------=-------=  */
  
  .checkbox-container label {
    padding: 6px 12px;
    margin: 0 8px 8px 0;
    font-size: 14px;
  }   
}


/* Desktop --------------------------------------------------*/
@media (min-width: 1400px) {
  main {
    width: 1400px;
    margin: 0 auto;
  }
  main p {
    padding-left: 20px;
  }
  header main img {
    margin: 120px 0 0 0;
  }
  #nav_box {
    width: 100vw;
    border-bottom: 1px solid #fff;
  }
  nav#desktop {
    width: 1400px;
    height: 100px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 auto;
  }
  nav#desktop span .logo {
    width: 120px;
  }
  nav#desktop span {
    font-family: NotoSansKR-Black;
    font-size: 20px;
    color: #fff;
  }
  nav#desktop span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  main {
    display: flex;
    flex-direction: row-reverse;
  }
  main .sub_text {
    font-size: 30px;
  }
  main .main_text {
    font-size: 94px;
    line-height: 114px;
  }
  .text_wrap {
    margin-top: 176px;
    position: relative;
    left: 20px;
  }
  #navigation-wrap {
    width: 100vw;
    height: 90px;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    background: #fff;
    z-index: 9999999;
  }

  .navigation .top_menu span {
    width: 200px;
    font-size: 20px;
  }
  .navigation .top_menu span:hover {
    color: #293f52;
  }
  .navigation {
    margin: 0 auto;
    width: 1400px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 24px;
    height: 90px;
    filter: none;
    overflow-x: hidden;
  }
  #service {
    margin-top: 200px;
  }

  #service .service_slogan {
    font-size: 50px;
    text-align: center;
  }

  /* wellpps 서비스소개 - 1400px */
  #service_laptop {
    width: 1400px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 200px auto 0 auto;
  }
  #service_laptop .left_explainBox {
    width: 754px;
  }
  .left_explainBox p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 46px;
    color: #1a1a1a;
  }
  .left_explainBox p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 24px;
    color: #a0a0a0;
  }
  .left_explainBox p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 18px;
    line-height: 20px;
    margin: 40px 0 80px 0 !important;
    color: #1a1a1a;
    line-height: 24px;
  }
  p.paragraph .blue {
    color: #0077ed;
    font-family: NotoSansKR-Medium;
    font-size: 20px;
  }  
  #service_laptop .right_explainBox {
    width: 622px;
  }
  .service_card {
    width: 100%;
    box-sizing: border-box;
    height: 306px;
    border-radius: 30px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
  .wellpps_img img.box_shadow {
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 30px;
  }

  .icon_box {
    width: 140px;
    height: 140px;
    border-radius: 16px;
    margin: 0 40px 0 68px;
  }
  .icon_box img.purple_1 {
    position: absolute;
    top: 18%;
    left: 12%;
    width: 64%;
    height: 64%;
    object-fit: cover;
  }

  .icon_box img.green_1 {
    position: absolute;
    top: 18%;
    left: 22%;
    width: 55%;
    height: 68%;
    object-fit: cover;
  }

  .icon_box img.yellow_1 {
    position: absolute;
    top: 12%;
    left: 12%;
    width: 72%;
    height: 72%;
    object-fit: cover;
  }

  .service_card .main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 32px;
    color: #1a1a1a;
    margin-bottom: 6px !important;
  }
  .service_card .sub_text {
    font-family: NotoSansKR-Light !important;
    font-size: 22px;
    color: #1a1a1a;
  }
  .service_card .sub_text_1 {
    font-family: NotoSansKR-Light !important;
    font-size: 22px;
    color: #1a1a1a;
  }

  /* phoneissue 서비스소개 - 1400px */
  #service_laptop .right_explainBox2 {
    width: 754px;
  }
  #service_laptop .left_explainBox2 {
    width: 580px;
    position: relative;
    top: 200px;
  }
  .left_explainBox2 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 46px;
    color: #1a1a1a;
  }
  .left_explainBox2 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 24px;
    color: #a0a0a0;
  }
  .left_explainBox2 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 18px;
    line-height: 20px;
    margin: 40px 0 80px 0 !important;
    color: #1a1a1a;
    line-height: 24px;
  }
  .phoneissue_img {
    display: flex;
    justify-content: space-between;
  }
  .phoneissue_img a img{
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 30px;
  }
  .phoneissue_img a.pi_2 {
    margin-top: 70px;
  }

  /* ppspay 서비스소개 - 1400px */
  #service_laptop .right_explainBox3 {
    width: 754px;
  }
  #service_laptop .left_explainBox3 {
    width: 520px;
    position: relative;
    top: 30px;
  }
  .left_explainBox3 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 46px;
    color: #1a1a1a;
  }
  .left_explainBox3 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 24px;
    color: #a0a0a0;
  }
  .left_explainBox3 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 18px;
    line-height: 20px;
    margin: 40px 0 80px 0 !important;
    color: #1a1a1a;
    line-height: 24px;
  }
  .ppspay_img {
    display: flex;
    justify-content: space-between;
  }
  .ppspay_img a img{
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 20px;
  }
  .ppspay_img a.py_2 {
    position:relative;
    top: 120px;
    right: 120px;
  }    

  /* 통신나눔 서비스소개 - 1400px */
  #service_laptop .right_explainBox4 {
    width: 754px;
  }
  #service_laptop .left_explainBox4 {
    width: 526px;
    position: relative;
    top: 60px;
  }
  .left_explainBox4 p.main_text {
    font-family: NotoSansKR-Bold !important;
    font-size: 46px;
    color: #1a1a1a;
  }
  .left_explainBox4 p.sub_text {
    font-family: NotoSansKR-Medium !important;
    font-size: 24px;
    color: #a0a0a0;
  }
  .left_explainBox4 p.paragraph {
    font-family: NotoSansKR-Light !important;
    font-size: 18px;
    line-height: 20px;
    margin: 40px 0 80px 0 !important;
    color: #1a1a1a;
    line-height: 24px;
  }
  .lab_img {
    position: relative;
    top: 32px;
  }
  .img_url_child {
    font-size: 16px;
    font-family: NotoSansKR-Medium;
    margin: 40px auto 0 auto;
    padding: 8px 36px;
    border-radius: 24px;
  }  
  .wellnanum_img a img{
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 20px;
  } 
  .lab_img img{
    box-shadow: 6px 3px 16px #00000024;
    border-radius: 30px;
  }    



  /* 업무방식 - 우리가 일하는 방식 ------------------------------------------------------------*/

  .workflow_text {
    width: 900px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: 30px auto 0 auto;
  }
  .workflow_text p {
    font-size: 18px;
    font-family: NotoSansKR-Light !important;
    text-align: center;
  }
  .workflow_box {
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 100px auto 0 auto;
  }
  .workflow_box_child {
    width: 312px;
    height: 312px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .workflow_box_child .main_text {
    font-family: GothamBlack;
    text-align: center;
    font-size: 30px;
    color: #20385b;
  }
  .workflow_box_child img {
    width: 180px;
    margin: 0 auto;
  }

  /* 조직구성 - 우리의 구성원들 -------------------------------------------------------*/

  .team_card_bundle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1400px;
    margin: 100px auto 0 auto;
  }
  .team_card {
    width: 700px;
    padding: 20px;
    margin: 0;
  }
  .team_wrap {
    height: 240px;
  }
  .team_wrap .emoji {
    margin-right: 30px;
  }
  .team_info .team_main {
    font-size: 32px;
  }
  .team_info .team_sub {
    font-size: 24px;
  }
  .team_wrap .emoji {
    width: 108px;
    height: 108px;
  }

  /* 연혁 -------------------------------------------------------------------*/
  #history_wrap {
    margin-top: 100px;
  }
  #history_wrap .year {
    padding: 0;
    width: 1400px;
    margin: 0 auto 60px auto;
  }
  .month_wrap {
    margin-top: 30px;
  }

  /* CONTACT 우리와 함께 하는 방법 ---------------------------------------------------*/
  .laptop_contact {
    width: 1400px;
    margin: 100px auto 0 auto;
    display: flex;
  }
  .address_wrap {
    flex-basis: 66%;
  }
  .address_margin {
    margin: 0 30px 0 0;
  }
  .address_child {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  .address_child .sub_text {
    font-family: NotoSansKR-Light !important;
    font-size: 20px;
  }
  .address_child .title {
    font-size: 24px;
    font-family: NotoSansKR-Bold !important;
    color: #0077ed;
  }
  .request_wrap {
    flex-basis: 36%;
    margin-left: 50px;
  }
  .map {
    width: 100%;
    height: 200px;
    background: #fff;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  .request_wrap {
    box-sizing: border-box;
    padding: 30px 50px 0px 50px;
    width: 100%;
    height: 890px;
    background: #fff;
    box-shadow: 0px 27px 66.42px 14.58px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  .request_title {
    font-family: NotoSansKR-Bold !important;
    font-size: 28px;
    display: flex;
    align-items: center;
  }
  .request_title img {
    width: 42px;
    position: relative;
    top: 3px;
  }
  .input_box_labtop {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }
  .textarea {
    box-sizing: border-box;
    padding: 12px;
    font-family: NotoSansKR-bold !important;
    font-size: 14px;
    border-radius: 8px;
    height: 104px;
    border: 1px solid #0077ed;
    box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
    margin-top: 10px;
    color: #0077ed;
    resize: none;
  }
  .textarea::placeholder {
    color: #d6d6d6;
  }  
  input.input_text {
    box-sizing: border-box;
    padding-left: 12px;
    font-family: NotoSansKR-Bold !important;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    border: 1px solid #0077ed;
    box-shadow: 6px 4px 12px rgb(0, 0, 0, 0.1);
    margin-top: 10px;
    color: #0077ed;
  }
  input.input_text::placeholder {
    color: #d6d6d6;
  }

  .radio-container label {
    padding: 8px 2px;
    margin: 0;
    font-size: 16px;
  }
  .request_btn {
    height: 50px;
    font-size: 18px;
    margin-top: 50px;
  }

  .map_Expand {
    height: 888px!important;
  }

  .address_wrap .map {
    height: 678px;
  }
  

  /* 푸터  --------------------------------------------------------------------------------*/
  .site_list {
    padding: 22px;
    width: 1400px;
    margin: 0 auto;
  }
  .footer_info {
    font-family: "NotoSansKR-Light";
    color: #808080;
    font-size: 20px;
    line-height: 32px;
    width: 1400px;
    margin: 0 auto;
  }
  .infomation_footer img {
    width: 10rem;
    margin: 20px 0;
  }
}
